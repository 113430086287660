import React, {useState, useEffect} from "react"
import {Link} from 'gatsby'

const Pagination = (props) => {
    let pages = []
    const [visibleCount, setVisibleCount] = useState(7)
    for(let i = 2; i <= props.pages - 1; i++) {
        pages.push(i)
    }
    if (props.count < visibleCount) {
        pages = pages.slice(0, visibleCount)
    } else {
        pages = pages.slice(props.count - visibleCount, props.count + 1)
    }
    useEffect(() => {
        if (window.innerWidth <= 576 ) {
            setVisibleCount(3)
        }
    }, [])
    return (
        <div className="pagination">
            <ul className="pagination-list">
                {props.pages > 1 &&
                    <li className="pagination-link"> 
                        <Link to={`/${props.pageName}`} activeClassName="active">1</Link>
                    </li>
                }
                {props.count !== 1 && props.count > visibleCount &&
                    <li className="pagination-dots">...</li>
                }
                {pages.map(page => {
                    return (
                        <li key={page} className="pagination-link">
                            <Link to={`/${props.pageName}/${page}`} activeClassName="active">{page}</Link>
                        </li>
                    )
                })}
                {props.pages > visibleCount && props.count < props.pages - 4 && 
                    <li className="pagination-dots">...</li>
                }
                {props.pages > 1 &&
                    <li className="pagination-link"> 
                        <Link to={`/${props.pageName}/${props.pages}`} activeClassName="active" >{props.pages}</Link>
                    </li>
                }
            </ul>
        </div>
    )
}

export default Pagination
