import React, {useState} from "react"
import Layout from "@components/layout"
import LandingCard from "@components/posts/LandingCard"
import Pagination from "@components/Pagination"
import Breadcrumbs from "@components/Breadcrumbs"
import LandingFooter from "@components/posts/LandingFooter"
import Seo from "@components/Seo.js"
import TheCategories from "@components/TheCategories"
import categories from "@content/landingCategories.json"
import filterBtn from "@images/filter.svg"

const WritingServices = ({pageContext}) => {
    const {content, pages, page, categoriesLink} = pageContext
    const steps = [
        {
            name: 'Writing services',
            path: '/writing-services'
        }
    ]
    
    const category = pageContext.category || ''
    const subcategory = pageContext.subcategory || ''
    
    const [showFilter, setShowFilter] = useState(false)

    const hideFilter = () => {
        setShowFilter(!showFilter)
    }

    const pageDataMain = {
        title: `Writing Services, Paper Help`,
        description: `⭐⭐⭐⭐⭐ The best online paper help and essay writing services. 🔓Confidential. ⏰On-time.`,
        keywords: 'writing service, writing services, online writing services, best online service, best writing service',
        robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        micromarking: `
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "item": {
                            "@id": "https://www.genuinewriting.com/",
                            "name": "GenuineWriting",
                            "@type": "Thing"
                        }
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "item": {
                            "@id": "https://www.genuinewriting.com/writing-services",
                            "name": "✔️ Writing Services",
                            "@type": "Thing"
                        }
                    }
                ]
            }
        `
    }

    const pageDataSubcategory = {
        title: `${subcategory} Writing Services`,
        description: `⭐⭐⭐⭐⭐ Get professional ${subcategory} help for money on ${category} level. 🔓Confidential. ⏰On-time.`,
        keywords: `${subcategory.toLowerCase()} help, write my ${subcategory.toLowerCase()}, pay for ${subcategory.toLowerCase()}`,
        robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        micromarking: `
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "item": {
                            "@id": "https://www.genuinewriting.com/",
                            "name": "GenuineWriting",
                            "@type": "Thing"
                        }
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "item": {
                            "@id": "https://www.genuinewriting.com/writing-services",
                            "name": "✔️ Writing Services",
                            "@type": "Thing"
                        }
                    }
                ]
            }
        `
    }

    const pageData = subcategory ? pageDataSubcategory : pageDataMain
    
    return (
        <Layout>
            <Seo {...pageData} />
            <div className="blog-intro page-intro">
                <div className="wrapper">
                    <Breadcrumbs steps={steps}/>
                    <h1 className="page-intro__title">
                        {subcategory ? 'Offers |  ' + subcategory : 'Choose Your Assignment Help'}
                        <span
                            className="filterBtn button button-primary"
                            role={'presentation'}
                            onClick={hideFilter}
                        >
                            <img src={filterBtn} alt="filter" width={30} height={30} />
                        </span>                    
                    </h1>
                </div>
            </div>

            <div className="blog-content wrapper">
                <div className="blog">
                    {content.map(item => <LandingCard  key={item.id} path={page} post={item} name="landing" footer={<LandingFooter postInfo={item}/>}/>)}
                </div>
            
                <div className="blog-categories">
                    <TheCategories 
                        categories={categories} 
                        currentCategories={category}
                        currentSubCategories={subcategory}
                        url="writing-services/category"
                        title="Assignment types"
                        showFilter={showFilter}
                        hideFilter={hideFilter}
                    />
                </div>
                <Pagination pageName={categoriesLink ? `writing-services/${categoriesLink}` : 'writing-services'} pages={pages} count={pageContext.counter}/>
            </div>
        </Layout>
    )
}

export default WritingServices
